<script lang="ts">
	/**
	 * All stylesheets should come first.
	 */
	import '@tickrr/ui/theme/app.postcss';

	import '../webapp.postcss';
	import 'iconify-icon';
	import 'tippy.js';
	import 'tippy.js/dist/tippy.css';
	import 'tippy.js/themes/light.css'; // All these imports must precede the rest.
	import 'tippy.js/animations/scale.css'; // This comment prevents eslint-perfectionist from re-organizing them.
	import '@twicpics/components/style.css';
	/**
	 * SKELETON SETUP
	 * Note: the 3 skeleton imports below MUST be in that order.
	 * @see https://github.com/skeletonlabs/skeleton/discussions/1854.
	 */

	import type { MetaTagsProps } from 'svelte-meta-tags';

	import { dev } from '$app/environment';
	import { afterNavigate, invalidate } from '$app/navigation';
	import { page } from '$app/stores';
	import {
		PUBLIC_PY_SERVER_WS_URL,
		PUBLIC_TWICPICS_PATH_PREFIX,
		PUBLIC_TWICPICS_URL
	} from '$env/static/public';
	import CookieConsentPopup from '$lib/components/CookieConsentPopup.svelte';
	import PosthogAnalytics from '$lib/components/PosthogAnalytics.svelte';
	import { chartPageDrawerSettings } from '$lib/components/modals/chart-page-drawer/helpers.ts';
	/**
	 * Remaining imports...
	 */
	import GoogleAnalytics from '$lib/google/Analytics.svelte';
	import MobileNavDrawer from '$lib/landing-pages/app-shell/header/MobileNavDrawer.svelte';
	import { mobileNavDrawerSettings } from '$lib/landing-pages/app-shell/header/helpers.ts';
	import { logger } from '$lib/logger';
	import { isInPwaMode } from '$lib/pwa/helpers';
	import {
		arrow,
		autoUpdate,
		computePosition,
		flip,
		inline,
		offset,
		shift
	} from '@floating-ui/dom';
	import {
		getDrawerStore,
		getModalStore,
		initializeStores,
		storePopup
	} from '@skeletonlabs/skeleton';
	import { QueryClientProvider } from '@tanstack/svelte-query';
	import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
	import { MOBILE_BREAKPOINT } from '@tickrr/lib/constants';
	import { ClerkLoaded, clerk } from '@tickrr/ui/auth';
	import MyDrawer from '@tickrr/ui/components/v2/elements/drawer/Drawer.svelte';
	import MyToast from '@tickrr/ui/components/v2/elements/toasts/MyToast.svelte';
	import MyModal from '@tickrr/ui/components/v2/modals/MyModal.svelte';
	import AppUpdateProvider from '@tickrr/ui/components/v2/modules/app/AppUpdateProvider.svelte';
	import AppVersionUpdateNotification from '@tickrr/ui/components/v2/modules/app/AppVersionUpdateNotification.svelte';
	import { useIDB } from '@tickrr/ui/idb';
	import { getStores, initStores } from '@tickrr/ui/stores';
	import { installTwicpics } from '@twicpics/components/sveltekit';
	import { inject } from '@vercel/analytics';
	import extend from 'just-extend';
	import { onMount } from 'svelte';
	import { MetaTags } from 'svelte-meta-tags';
	import { pwaInfo } from 'virtual:pwa-info';

	import type { LayoutData } from './$types.ts';

	import { addPublisherDrawerSettings } from './(app)/backoffice/(components)/sections/add-publisher-drawer/helpers';
	import { editChangelogDrawerSettings } from './(app)/backoffice/(components)/sections/edit-changelog-drawer/helpers';
	import { updatePublisherDrawerSettings } from './(app)/backoffice/(components)/sections/edit-publisher-drawer/helpers';

	export let data: LayoutData;
	let innerWidth: number; // Setting initial to undefined, rather than 0, ensures desktop header is rendered first by default.
	$: ({ queryClient, session, supabase } = data);

	initStores(PUBLIC_PY_SERVER_WS_URL);
	initializeStores();
	const drawerStore = getDrawerStore();
	const modalStore = getModalStore();
	const {
		idbStore,
		isMacintoshStore,
		isMobileStore,
		isPwaStore,
		isWebshareSupportedStore,
		sbStore
	} = getStores([
		'isMobileStore',
		'idbStore',
		'isMacintoshStore',
		'isPwaStore',
		'isWebshareSupportedStore',
		'sbStore'
	]);

	// Init external modules...
	storePopup.set({ arrow, autoUpdate, computePosition, flip, inline, offset, shift });

	logger.debug('Initializing Vercel Analytics...');
	inject({
		/**
		 * FIXME: changed this to "auto" because referencing dynamic variables was breaking pre-rendering for our
		 * FIXME: `/(landing-pages)/` files. If events are sent to Vercel for our develpoment instances, we need to
		 * FIXME: change this back to the conditional expression.
		 */
		mode: 'auto'
		// dev || !!env.PUBLIC_PLAYWRIGHT || env.PUBLIC_VERCEL_PREVIEW_BUILD === 'true'
		// 	? 'development'
		// 	: 'production'
	});

	logger.debug('Initializing TwicPics...');
	installTwicpics({
		anticipation: 0.1, // default: 0.2
		domain: PUBLIC_TWICPICS_URL,
		env: 'production',
		path: PUBLIC_TWICPICS_PATH_PREFIX
	});

	const resetScrollToTop = () => {
		// Mobile...
		if ($isMobileStore && !$isPwaStore) {
			window.scrollTo({
				behavior: 'instant',
				top: 0
			});
			return;
		}
		// Tablet && PWA...
		const scrollEl = document.querySelector('#page');
		scrollEl?.scrollTo({
			behavior: 'instant',
			top: 0
		});
	};

	/**
	 * Note that both these are only calculated 1x, here, at top-level,
	 * rather than as-needed at component level, or reactively with $: statements
	 * because browser details will not change.
	 */
	onMount(async () => {
		if (dev) {
			logger.info('Loading root layout...');
		}

		if (isInPwaMode() === true) {
			isPwaStore.set(true);
			logger.info('webapp is installed...');
		}

		if (navigator.share !== undefined) {
			isWebshareSupportedStore.set(true);
		}

		isMacintoshStore.set(navigator.userAgent.includes('Mac'));

		const idb = await useIDB();
		idbStore.set(idb);

		/**
		 * HACK
		 *
		 * On first load, profiles are not always loaded (leading to an infinite loading state for
		 * all Profile components, since Clerk.user is still defined and <SignedIn> components render, but
		 * `$page.data.profile` is still undefined).
		 *
		 * I have not fully diagnosed the issue, but I believe it is caused by the verifyClerkSession(...) func
		 * invocation in `hooks.server.ts`. If the session cookie is expired -> some logic is run to re-new it, but
		 * it may not be available to our trpc context (see `context.ts`) by the time needed to load the user's profile.
		 *
		 * This simple hack is rather benign. No need to rush a solution to the underlying problem.
		 */
		setTimeout(async () => {
			await invalidate('profile::data');
		}, 500);
	});

	// On each navigation, close any open drawers and modals.
	// Doing so here is MUCH more efficient than by button/link in each respective modal component.
	afterNavigate(() => {
		logger.debug('afterNavigate: closing drawers and modals...');
		drawerStore.close();
		modalStore.clear();
		resetScrollToTop();
	});

	// Generate web manifest.
	const webManifestLink = pwaInfo ? pwaInfo.webManifest.linkTag : '';

	// Calculate mobile mode.
	$: isMobileViewport = innerWidth < MOBILE_BREAKPOINT;
	$: isMobileStore.set(isMobileViewport);

	// Make Supabase client globally available.
	$: sbStore.set(supabase);

	$: logger.debug('session', session);
	$: logger.debug('$clerk.user', $clerk?.user);
	$: logger.debug('$page.data.profile', $page.data.profile);

	let metaTags: MetaTagsProps;
	$: metaTags = extend(true, {}, data.defaultMetaTags, $page.data.metaTags);
</script>

<svelte:window bind:innerWidth />

<svelte:head>
	<!-- Vite-plugin-pwa support. See https://vite-pwa-org.netlify.app/frameworks/sveltekit.html. -->
	<!-- eslint-disable-next-line svelte/no-at-html-tags -->
	{@html webManifestLink}
</svelte:head>

<AppVersionUpdateNotification />
<CookieConsentPopup />
<MetaTags {...metaTags} />
<GoogleAnalytics />
<PosthogAnalytics />
<!-- <GoogleAdSense /> -->

<!-- Any elements that make use of svelte query must  be wrapped in the QueryClientProvider. -->
<QueryClientProvider client={queryClient}>
	<SvelteQueryDevtools buttonPosition="bottom-left" />
	<AppUpdateProvider />

	<MyModal />
	<MyToast />
	<MyDrawer>
		<!--  These are in addition to the base drawers available, such as the mobileSidebar drawer, etc. -->
		{#if $drawerStore.id === updatePublisherDrawerSettings.id}
			{#await import('./(app)/backoffice/(components)/sections/edit-publisher-drawer/EditPublisherDrawer.svelte') then { default: EditPublisherDrawer }}
				<EditPublisherDrawer />
			{/await}
		{:else if $drawerStore.id === addPublisherDrawerSettings.id}
			{#await import('./(app)/backoffice/(components)/sections/add-publisher-drawer/AddPublisherDrawer.svelte') then { default: AddPublisherDrawer }}
				<AddPublisherDrawer />
			{/await}
		{:else if $drawerStore.id === editChangelogDrawerSettings.id}
			{#await import('./(app)/backoffice/(components)/sections/edit-changelog-drawer/EditChangelogDrawer.svelte') then { default: EditChangelogDrawer }}
				<EditChangelogDrawer />
			{/await}
		{:else if $drawerStore.id === chartPageDrawerSettings.id}
			{#await import('$lib/components/modals/chart-page-drawer/ChartPageDrawer.svelte') then { default: ChartPageDrawer }}
				<ChartPageDrawer />
			{/await}
		{:else if $drawerStore.id === mobileNavDrawerSettings.id}
			<!-- 
			This component we do NOT dynamically import. It is rather lightweight, and we want it instantly
			available since the component exists on our landing page(s).
			-->
			<MobileNavDrawer />
		{/if}
	</MyDrawer>
	<!-- Router Slot -->
	<!-- We need auth to be loaded and configured for all our pages to work properly. -->
	<ClerkLoaded>
		<slot />
	</ClerkLoaded>
	<!-- ---- / ---- -->
</QueryClientProvider>
