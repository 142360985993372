<!-- 
	@component
	
	HEADER (Drawer)
    
    @prop title - Title of the drawer.

-->

<script lang="ts">
	import DrawerCloseButton from './CloseButton.svelte';

	export let title: string;
</script>

<header
	class="
		flex
		h-20
		w-full
		shrink-0
		items-center
		justify-between
		pl-8
		rounded-tl-token
		rounded-tr-token
	"
>
	<h3 class="text-xl font-bold">
		{title}
	</h3>

	<DrawerCloseButton classes="mr-1" />
</header>
