<script lang="ts">
	import { page } from '$app/stores';
	import { ONE_YEAR_IN_MS } from '@tickrr/lib/constants';
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	const EXEMPT_ROUTES = [/^\/$/, /^\/cookies/, /^\/i\//];

	let cookiesAreAccepted = true;

	function setCookieConsentCookie() {
		const date = new Date();
		date.setTime(date.getTime() + ONE_YEAR_IN_MS);
		document.cookie = `tickrr-cookie-consent=true; expires=${date.toUTCString()}; path=/; SameSite=Lax`;

		cookiesAreAccepted = true;
	}

	onMount(() => {
		if (!document.cookie.includes('tickrr-cookie-consent=true')) {
			cookiesAreAccepted = false;
		}
	});

	$: shouldDisplay = !EXEMPT_ROUTES.some((route) => route.test($page.url.pathname));
</script>

{#if !cookiesAreAccepted && shouldDisplay}
	<div
		data-testid="cookie-consent-popup"
		class="
		card
		fixed
		z-[99999]
		flex
		gap-4 border-t
		border-base p-4
		!ring-0
		max-tablet:inset-x-0
		max-tablet:bottom-0 max-tablet:!rounded-none tablet:bottom-2
		tablet:right-2 tablet:max-w-[240px] tablet:flex-col tablet:border
		"
		in:fade
	>
		<div class="flex max-tablet:gap-x-2 tablet:flex-col tablet:gap-y-4">
			<!-- TABLET+ ICON -->
			<iconify-icon
				icon="material-symbols-light:cookie-outline"
				height="80px"
				class="mx-auto max-tablet:hidden"
			/>

			<!-- MOBILE ICON -->
			<iconify-icon
				icon="material-symbols-light:cookie-outline"
				height="60px"
				class="mx-auto tablet:hidden"
			/>

			<p class="max-tablet:text-sm">
				We use cookies to ensure you get the best experience on our website.

				<a href="/cookies" class="anchor">Learn more</a>
			</p>
		</div>

		<button type="button" class="variant-filled btn" on:click={setCookieConsentCookie}>
			<!-- <iconify-icon icon="mdi:check" /> -->
			<span>Accept</span>
		</button>
	</div>
{/if}
