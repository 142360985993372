<script lang="ts">
	import { onMount } from 'svelte';

	import { DEFAULT_SETTINGS, getStores, settingsValidator } from '../../../../stores';

	const { settingsStore } = getStores(['settingsStore']);

	const validateSettingsStore = () => {
		const res = settingsValidator.safeParse(settingsStore);
		if (res.success) {
			// Do nothing
		} else {
			// If the settings store is invalid, delete it
			settingsStore.set(DEFAULT_SETTINGS);
		}
	};

	// Take any necessary steps for migrations here (e.g., deleting localStorage keys, etc.)
	onMount(() => {
		/**
		 * For now, ensure no one can use ListMode It is not ready for Production.
		 * // TODO: remove in future.
		 */
		validateSettingsStore();
		settingsStore.set({ ...settingsStore, feedLayout: 'GRID' });

		// ...
		// ...
	});
</script>

<slot />
