<!-- 
	@component

	NEW POST DRAWER
	
	Displayed on mobile only.

-->

<script lang="ts">
	import DrawerHeader from '../../../elements/drawer/Header.svelte';
	import DrawerWrapper from '../../../elements/drawer/Wrapper.svelte';
	import NewPostForm from './NewPostForm.svelte';
</script>

<DrawerWrapper>
	<DrawerHeader title="Create a New Post" />
	<div class="h-full grow pb-6">
		<NewPostForm focusOnMount={false} />
	</div>
</DrawerWrapper>
