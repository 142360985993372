<script lang="ts">
	import { Drawer, getDrawerStore } from '@skeletonlabs/skeleton';

	import { newPostDrawerSettings } from '../../modals/new-post';
	import NewPostDrawer from '../../modals/new-post/_internal/NewPostDrawer.svelte';
	import MobileSidebar, {
		mobileSidebarDrawerSettings
	} from '../../modules/app/MobileSidebar.svelte';
	import PreventBodyScrollWhenDrawerIsOpen from './PreventBodyScrollWhenDrawerIsOpen.svelte';

	const drawerStore = getDrawerStore();
</script>

<!-- 
	 most settings are set in ./defaultSettings.ts (duration, background, etc.).
-->
<Drawer border="border-surface-300/25" padding="p-0" regionDrawer="!overscroll-y-contain">
	{#if $drawerStore.id === mobileSidebarDrawerSettings.id}
		<PreventBodyScrollWhenDrawerIsOpen>
			<MobileSidebar />
		</PreventBodyScrollWhenDrawerIsOpen>
	{:else if $drawerStore.id === newPostDrawerSettings.id}
		<PreventBodyScrollWhenDrawerIsOpen>
			<NewPostDrawer />
		</PreventBodyScrollWhenDrawerIsOpen>
	{/if}

	<!-- Additional drawers, based on the application, can be added here -->
	<slot />
	<!-- / -- / -- / -->
</Drawer>
