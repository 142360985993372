<!-- 
	@component
	
	CLOSE BUTTON (Drawer)

-->
<script lang="ts">
	import { getDrawerStore } from '@skeletonlabs/skeleton';

	const drawerStore = getDrawerStore();

	export let classes: string = '';

	function closeModal() {
		drawerStore.close();
	}
</script>

<!-- close button -->
<button
	data-testid="modal-close-button"
	type="button"
	class="
	btn
    py-3
    rounded-token
    tablet:variant-ghost
	{classes}
    "
	on:click|stopPropagation={closeModal}
>
	<!-- <iconify-icon icon="tabler:letter-x" height="24" class="shrink-0" /> -->
	<iconify-icon icon="tabler:x" class="shrink-0"></iconify-icon>
	<span class="sr-only">Close</span>
</button>
