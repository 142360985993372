<script>
	import { updated } from '$app/stores';

	function refreshPage() {
		window.location.reload();
	}
</script>

{#if $updated}
	<div
		data-testid="app-version-update-notification"
		class="pointer-events-auto fixed inset-0 z-[9999] cursor-not-allowed bg-surface-300/10"
	>
		<div
			data-testid="app-version-update-notification__toast"
			class="card fixed bottom-4 right-4 w-full max-w-xs cursor-default border border-base p-4"
		>
			<h2 class="text-lg font-bold text-surface-50">Update Available</h2>

			<p>There is a new version of the app. Please refresh the page to update.</p>

			<button type="button" class="variant-filled btn mt-4 w-full" on:click={refreshPage}>
				<span> Refresh </span>
			</button>
		</div>
	</div>
{/if}
