<script context="module" lang="ts">
	const BUTTONS: Record<
		string,
		{
			href: string;
			label: string;
		}[]
	> = {
		company: [
			{
				href: '/blog',
				label: 'Blog'
			},
			{
				href: '/faq',
				label: 'FAQ'
			},
			{
				href: '/changelog',
				label: 'Changelog'
			},
			{
				href: 'mailto:support@tickrr.io',
				label: 'Support'
			},

			{
				href: '/subscribe',
				label: 'Pricing'
			}
		],
		features: [
			{
				href: '/features#charts',
				label: 'Charts'
			},
			{
				href: '/features#screener',
				label: 'Screener'
			},
			{
				href: '/features#markets',
				label: 'Markets'
			},
			{
				href: '/features#news',
				label: 'News'
			},
			{
				href: '/features#alerts',
				label: 'Price alerts'
			},
			{
				href: '/extension/install',
				label: 'Chrome ext.'
			},
			{
				href: '/features#misc',
				label: 'And more...'
			}
		],
		solutions: [
			{
				href: '/for-day-traders',
				label: 'Day traders'
			},
			{
				href: '/for-crypto-traders',
				label: 'Crypto traders'
			},
			{
				href: '/for-value-investors',
				label: 'Value investors'
			},
			{
				href: '/for-beginners',
				label: 'Beginners'
			}
		]
	};
</script>

<script lang="ts">
	import { getDrawerStore } from '@skeletonlabs/skeleton';
	import NameAndLogo from '@tickrr/ui/components/v2/modules/app/NameAndLogo.svelte';

	const drawerStore = getDrawerStore();

	function closeMobileDropdown() {
		drawerStore.close();
	}
</script>

<nav data-testid="header__mobile-dropdown" class="flex flex-col gap-y-6 overflow-hidden p-6">
	<div class="grid grid-cols-3">
		<div></div>
		<a href="/" class="!flex items-center justify-center text-primary-500">
			<NameAndLogo
				classes="!text-2xl"
				logoProps={{
					svgProps: {
						height: 24,
						width: 40
					},
					wrapperProps: {
						classes: '',
						height: 'h-8',
						width: 'w-10'
					}
				}}
			/>
		</a>
		<div class="flex items-center justify-end">
			<button
				type="button"
				class="variant-filled-surface btn-icon"
				on:click={closeMobileDropdown}
			>
				<iconify-icon icon="tabler:x"></iconify-icon>
			</button>
		</div>
	</div>

	<div class="grid grid-cols-2 gap-x-4 gap-y-6">
		<!-- Features column -->
		<div>
			<h3>Features</h3>
			<div class="space-y-2">
				{#each BUTTONS.features as btn (btn)}
					<a href={btn.href} class="nav-link" on:click={closeMobileDropdown}>
						{btn.label}
					</a>
				{/each}
			</div>
		</div>

		<!-- Company and Solutions column -->
		<div class="space-y-6">
			<div>
				<h3>Company</h3>
				<div class="space-y-2">
					{#each BUTTONS.company as btn (btn)}
						<a href={btn.href} class="nav-link" on:click={closeMobileDropdown}>
							{btn.label}
						</a>
					{/each}
				</div>
			</div>
			<div>
				<h3>Solutions for</h3>
				<div class="space-y-1">
					{#each BUTTONS.solutions as btn (btn)}
						<a href={btn.href} class="nav-link" on:click={closeMobileDropdown}>
							{btn.label}
						</a>
					{/each}
				</div>
			</div>
		</div>
	</div>

	<a
		href="/subscribe"
		class="relative flex h-40 w-full items-end overflow-hidden border border-primary-500 rounded-container-token promax:h-60"
	>
		<enhanced:img
			alt="Chart page"
			src="$images/landingpage/chart-page__full-screen.png?blur=10"
			class="absolute inset-0 object-cover object-center opacity-90"
		/>
		<div class="z-10 flex h-full w-full items-center justify-center border-t border-base">
			<span class="text-center text-4xl font-black text-surface-50">
				Click for <br /> 14 days free
			</span>
		</div>
	</a>

	<div class="flex gap-x-3">
		<a href="/signup" class="variant-filled btn w-full" on:click={closeMobileDropdown}>
			<span>Start trial</span>
		</a>
		<a href="/signin" class="variant-filled-surface btn w-full" on:click={closeMobileDropdown}>
			<span>Log in</span>
		</a>
	</div>
</nav>

<style lang="postcss">
	h3 {
		@apply mb-4 text-base font-semibold;
	}
	a.nav-link {
		@apply block w-full text-xl font-semibold text-surface-50;
	}
</style>
