<!-- 
    @component 
    
    POSTHOG ANALYTICS

    @see https://posthog.com/tutorials/svelte-analytics for a _very_ in-depth guide.
-->
<script>
	import { browser, dev } from '$app/environment';
	import { afterNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import { env } from '$env/dynamic/public';
	import { PUBLIC_POSTHOG_API_KEY } from '$env/static/public';
	import { logger } from '@tickrr/lib/logger';
	import posthog from 'posthog-js';
	import { onMount } from 'svelte';

	// Because SvelteKit operates as a SPA, we need to manually count
	// our pageviews. See https://posthog.com/tutorials/svelte-analytics.
	if (browser) {
		afterNavigate(() => posthog.capture('$pageview'));
	}

	// Posthog can't be initizalized until it hits the browser.
	onMount(() => {
		if (!dev && env.PUBLIC_VERCEL_PREVIEW_BUILD !== 'true') {
			logger.debug('Initializing Posthog Analytics...');
			posthog.init(PUBLIC_POSTHOG_API_KEY, {
				api_host: 'https://app.posthog.com',
				capture_pageleave: false,
				capture_pageview: false
			});
		}
	});

	// For logged in users, we can set their username and user ID.
	// Prefer $page.data.profile (over $profileStore) because it is available sooner.
	// Because this component runs immediately when the main layout mounts, we want to
	// set this properly as soon as we can.
	$: if (browser && $page.data.profile) {
		posthog.identify($page.data.profile?.id ?? undefined, {
			username: $page.data.profile?.username ?? null
		});
	}
</script>
