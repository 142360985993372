<!-- 
	@component
	
	MODAL

	Singleton implementation of Skeleton modal.
	@see https://www.skeleton.dev/utilities/modals.
	
	@note ```!overflow-y-visible``` is needed to ensure sticky header continues to work
	after Skeleton@1.11.0. See https://github.com/skeletonlabs/skeleton/pull/1695.
	
	@note It is important that `transitions` is set to `false` to prevent it from flickering
	from Shallow Routing after first page load. This is a weird bug, caused by the fact that 
	sveltekit calls a "popstate" navigation for the first Shallow Routing navigation (but no others), 
	at least as of 2.5.0.
-->
<script>
	import { Modal } from '@skeletonlabs/skeleton';
</script>

<Modal
	position="relative !overflow-y-visible items-start px-0 pt-2 tablet:pt-[68px] pb-20 tablet:py-4 tablet:px-4"
	regionBackdrop="!overscroll-y-contain !p-0 !bg-component/10 backdrop-blur-[2px]"
	rounded="!rounded-token"
	transitions={false}
/>
