<script context="module" lang="ts">
	import { logger } from '@tickrr/lib/logger';
	const BODY_LOCATOR = 'body';
	const MODAL_BACKDROP_LOCATOR = '[data-testid="drawer-backdrop"]';
</script>

<script lang="ts">
	import { dev } from '$app/environment';
	import { onDestroy, onMount } from 'svelte';

	// Prepare to prevent body tag from scrolling when drawer is open
	let preventScroll: (e: Event) => void;
	let bodyEl: HTMLBodyElement | null = null;
	let backdropEl: HTMLDivElement | null = null;

	// Attach event listener on mount.
	onMount(() => {
		if (dev) logger.debug('Mounting drawer; attaching preventScroll handler to body');

		// Attach to body.
		bodyEl = document.querySelector(BODY_LOCATOR);

		// Get a ref to the backdrop element each time it mounts.
		// Only getting the ref in an onMount hook doesn't work, because the backdrop
		// is removed from the DOM when the drawer is closed, and re-added when it's opened.
		backdropEl = document.querySelector(MODAL_BACKDROP_LOCATOR);

		// Re-calculate the scroll handler on mount, so that we can use the latest backdropEl ref.
		preventScroll = (e: Event) => {
			if (e.target === backdropEl) {
				e.preventDefault();
			}
		};

		// We must use the { passive: false } option to prevent scrolling;
		// 'touchmove' event defaults to true for passive, which means we can't preventDefault.
		// See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
		bodyEl?.addEventListener('touchmove', preventScroll, { passive: false });
	});

	// Remove event listener on unmount.
	onDestroy(() => {
		if (dev) logger.debug('Unmounting drawer; removing preventScroll handler to body');
		backdropEl = null;
		bodyEl?.removeEventListener('touchmove', preventScroll);
	});
</script>

<slot />
